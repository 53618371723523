/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const CREATE_PAYMENT_PLAN = gql`
  mutation createPaymentPlan($masterEntityId: ID!, $defaultRate: Float!, $interestRate: Float!, $installments: [InstallmentInputType]!) {
    createPaymentPlan(masterEntityId: $masterEntityId, defaultRate: $defaultRate, interestRate: $interestRate, installments: $installments) {
      amortizationSchedule {
        id
      }
    }
  }
`;

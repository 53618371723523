import { gql } from '@apollo/client';
import { ShoppingFields } from '../fragments';

export const PREOFFER_SHOPPING_CART = gql`
  query preofferShoppingCart($invoicesId: [Int]!) {
    preofferShoppingCart(invoicesId: $invoicesId) {
      ... ShoppingFields
    }
  }
  ${ShoppingFields}
`;

export const OFFER_SHOPPING_CART = gql`
  query offerShoppingCart($offerSelected: [OfferSelectionType]!) {
    offerShoppingCart(offerSelected: $offerSelected) {
      ... ShoppingFields
    }
  }
  ${ShoppingFields}
`;

export const ORDERING_SHOPPING_CART = gql`
  query orderingShoppingCart($purchaseOrders: [OrderingSelectionType]!) {
    orderingShoppingCart(purchaseOrders: $purchaseOrders) {
      ... ShoppingFields
    }
  }
  ${ShoppingFields}
`;

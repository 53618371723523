/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { FileFields } from '../fileField';

export const UserIdentification = gql`
  fragment UserIdentification on UserType {
    id
    firstName
    lastName
    completeName @client
    email
    rut
    phoneNumber
    picture {
      ... FileFields
    }
  }
  ${FileFields}
`;

import { gql } from '@apollo/client';

export const ALERT_COMPANY_BALANCE_NOT_EQUAL = gql`
  mutation AlertCompanyBalanceNotEqual($rut: String!) {
    alertCompanyBalanceNotEqual(rut: $rut) {
      success
    }
  }
`;

export const REQUEST_SURPLUS_WITHDRAWAL = gql`
  mutation RequestSurplusWithdrawal($companyId: Int!, $accountId: Int!) {
    requestSurplusWithdrawal(companyId: $companyId, accountId: $accountId) {
      success
      message
    }
  }
`;

/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const ContentTypeFields = gql`
  fragment ContentTypeFields on ContentTypeType {
    id
    model
    appLabel
  }
`;

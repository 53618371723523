import { gql } from '@apollo/client';
import { InstallmentFields, InstallmentManagerFields, SimpleAmortizationScheduleFragment } from './fragment';
import { MasterEntityBasicField, RiskBlacklistFields } from '../customers/fragment';
import { ContentTypeFields } from '../third_party/fragments';

export const AMORTIZATION_SCHEDULE = gql`
  query amortizationSchedules (
    $first: Int
    $offset: Int
    $status: String
    $status_In: [String]
    $contentType_Model: String
    $orderBy: String
    $globalFilter: String
    $masterEntity_Id: ID
    $masterEntity_Country_Id: ID
  ) {
    amortizationSchedules (
      first: $first
      offset: $offset
      status: $status
      status_In: $status_In
      contentType_Model: $contentType_Model
      orderBy: $orderBy
      globalFilter: $globalFilter
      masterEntity_Id: $masterEntity_Id
      masterEntity_Country_Id: $masterEntity_Country_Id
    ) {
      totalPages
      totalCount
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ... SimpleAmortizationScheduleFragment
          masterEntity {
            ... MasterEntityBasicField
            riskBlacklist {
              ...RiskBlacklistFields
            }
          }
          contentType {
            ... ContentTypeFields
          }
          installments {
            ... InstallmentFields
            installmentManager {
              ... InstallmentManagerFields
            }
          }
        }
      }
    }
  }
  ${SimpleAmortizationScheduleFragment}
  ${InstallmentFields}
  ${InstallmentManagerFields}
  ${MasterEntityBasicField}
  ${ContentTypeFields}
  ${RiskBlacklistFields}
`;

export const GET_PAYMENT_PLAN = gql`
  query getPaymentPlan($principalAmount: Float!, $monthlyRate: Float!, $upfrontAmount: Float!, $installmentDates: [Date]!, $upfrontPaymentDate: Date!) {
    getPaymentPlan(principalAmount: $principalAmount, monthlyRate: $monthlyRate, upfrontAmount: $upfrontAmount, installmentDates: $installmentDates, upfrontPaymentDate: $upfrontPaymentDate) {
      paymentDate
      amortizationToPay
      interestAmountToPay
      totalAmountToPay
    }
  }
`;

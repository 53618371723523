import { gql } from '@apollo/client';
import { MoneyFields } from '../moneyFields';

export const SimplePurchaseOrderFields = gql`
  fragment SimplePurchaseOrderFields on OrderingPurchaseOrderType {
    id
    orderNumber
    totalAmount {
      ... MoneyFields
    }
    status
  }
  ${MoneyFields}
`;

export const OrderingEvaluationRequestField = gql`
  fragment OrderingEvaluationRequestField on OrderingSimulationEvaluationRequestType {
    id
    requestDate
    rejectionDate
    evaluationAnalystDescription
    finalAnalystDescription
    rejectionReason
    otherRejectionReason
    offeredDate
  }
`;

export const OrderingOfferFields = gql`
  fragment OrderingOfferFields on OrderingOfferType {
    id
    orderingFinancedAmount {
      ...MoneyFields
    }
    invoiceIssuedDate
    invoiceDateToPay
  }
  ${MoneyFields}
`;

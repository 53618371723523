/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const RatificationActionsBasicFields = gql`
  fragment RatificationActionsBasicFields on RatificationActionType {
    id
    actionType
    comment
    createdAt
    channel
    responded
  }
`;
